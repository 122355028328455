import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { get } from "../../modules/auth/core/helpers";
import { post } from "../../modules/auth/core/_requests";
import Footer from "../../modules/Footer";

const DashboardWrapper = () => {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>([]);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await get("api/get-tasks");
      setTasks(response.data);
      console.log(response.data);
      const customerNotifications = await post(
        "api/get-users-active-notifications",
        {
          id: currentUser?.customer_id,
        },
      );
      setNotifications(customerNotifications.data);
    }
    fetchMyAPI();
  }, [currentUser]);

  const removeNotifications = async () => {
    await post("api/remove-notifications", {
      id: currentUser?.customer_id,
    });
  };
  return (
    <>
      <div className="app-header">
        <div className="tf-container">
          <div className="tf-topbar d-flex justify-content-center align-items-center">
            <img
              src="/images/logo-light.png"
              className="hero-logo"
              alt="logo"
            />
            <div
              onClick={removeNotifications}
              className="right-30 d-flex align-items-center gap-4"
            >
              <Link
                to="/notifications"
                id="btn-popup-up"
                className="icon-notification1"
              >
                {notifications && notifications.length > 0 && (
                  <span>{notifications.length}</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card-secton">
        <div className="tf-container">
          <div className="tf-balance-box">
            <div className="balance">
              <div className="row">
                <div className="col-6 br-right">
                  <div className="inner-left">
                    <p>Your Car:</p>
                    <h3>
                      {currentUser &&
                        currentUser.customer &&
                        currentUser.customer.item}
                    </h3>
                  </div>
                </div>
                <div className="col-6 br-right">
                  <div className="text-right tf-topbar d-flex justify-content-end align-items-end">
                    <a
                      className="user-info d-flex justify-content-between align-items-center"
                      href="/dashboard"
                    >
                      <div className="content">
                        <h4 className="">{currentUser && currentUser.name}</h4>
                      </div>
                      <img
                        src={currentUser && currentUser?.customer?.image}
                        alt="image1"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="tf-container">
          <div className="tf-title d-flex justify-content-center mb-6 mt-4">
            <h3 className="fw_6">Your services</h3>
          </div>
          <ul className="box-service mt-3">
            {tasks &&
              tasks.length &&
              tasks.map((p) => (
                <li>
                  <Link
                    key={p.slug}
                    to={`/task/${p.client_id}/${p.slug}`}
                    className="custom-list d-flex align-items-center px-5 py-4"
                  >
                    {p.title}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Footer page="dashboard" />
    </>
  );
};

export { DashboardWrapper };
