import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";
import { post } from "../auth/core/_requests";

function Completion(props) {
  const [data, setData] = useState<any | null>("");
  const { stripePromise } = props;
  useEffect(() => {
    const stripePromiseFilled = loadStripe(stripePromise);
    if (!stripePromiseFilled) return;
    stripePromiseFilled.then(async (stripe) => {
      const url = new URL(window.location.toString());
      const clientSecret = url.searchParams.get("payment_intent_client_secret");
      const data = await stripe?.retrievePaymentIntent(clientSecret as string);
      if (data?.paymentIntent?.status === "succeeded") {
        post("api/save-stripe-id", { data });
      }

      setData(data);
    });
  }, [stripePromise]);

  return (
    <>
      <div className="wrap-success">
        <div className="success_box">
          <div className="icon-1 ani3">
            <span className="circle-box lg bg-circle check-icon"></span>
          </div>
          <div className="icon-2 ani5">
            <span className="circle-box md bg-circle"></span>
          </div>
          <div className="icon-3 ani8">
            <span className="circle-box md bg-circle"></span>
          </div>
          <div className="icon-4 ani2">
            <span className="circle-box sm bg-circle"></span>
          </div>

          <div className="content">
            <div className="top">
              <h2>Successful!</h2>
              <p>Your payment was successful!</p>
            </div>
            <div className="tf-spacing-16"></div>
            <div className="inner">
              <p className="secondary_color fw_6">Bill Payment</p>
              <h1>
                $
                {data?.paymentIntent?.amount &&
                  data?.paymentIntent?.amount / 100}
              </h1>
            </div>
          </div>
          <Link to="/dashboard" className="tf-btn accent large">
            Done
          </Link>
        </div>

        <span className="line-through through-1"></span>
        <span className="line-through through-2"></span>
        <span className="line-through through-3"></span>
        <span className="line-through through-4"></span>
        <span className="line-through through-5"></span>
        <span className="line-through through-6"></span>
      </div>
    </>
  );
}

export default Completion;
