import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { post } from "../auth/core/_requests";
import { useAuth } from "../../modules/auth";
import Footer from "../../modules/Footer";

function Notifications(props) {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState<any>([]);
  useEffect(() => {
    async function fetch() {
      const customerNotifications = await post(
        "api/get-users-app-notifications",
        {
          id: currentUser?.customer_id,
        },
      );
      setNotifications(customerNotifications.data);
    }
    if (currentUser) {
      fetch();
    }
  }, [currentUser]);

  return (
    <>
      <div className="header is-fixed">
        <div className="tf-container">
          <div className="tf-statusbar d-flex justify-content-center align-items-center">
            <Link to="/dashboard" className="back-btn">
              {" "}
              <i className="icon-left"></i>{" "}
            </Link>
            <h3>Notifications</h3>
          </div>
        </div>
      </div>
      <div id="app-wrap">
        <div className="app-section st1 mt-1 mb-5 bg_white_color">
          <div className="tf-container">
            <div className="trading-month">
              <div className="group-trading-history mb-5">
                {notifications &&
                  notifications.map((item) => (
                    <Link
                      className="tf-trading-history"
                      to={`/car/${item.car_id}?note_id=${item.note_id}`}
                    >
                      <div className="inner-left">
                        <div className="icon-box rgba_primary">
                          <i className="icon icon-electricity-1"></i>
                        </div>
                        <div className="content">
                          <h4>
                            {item?.creator?.name} has commented on your note
                          </h4>
                          <Moment fromNow>{item?.created_at}</Moment>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Footer page="notes" />
      </div>
    </>
  );
}

export default Notifications;
