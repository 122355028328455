/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useCallback } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, post } from "../core/_requests";
import { useAuth } from "../core/Auth";

const loginSchema = Yup.object().shape({
  phone: Yup.string()
    .min(10, "Minimum 3 symbols")
    .max(12, "Maximum 12 symbols")
    .required("Phone is required"),
});

const codeSchema = Yup.object().shape({
  code: Yup.string()
    .min(5, "Minimum 3 symbols")
    .max(8, "Maximum 50 symbols")
    .required("Code is required"),
});

const initialPhoneValues = {
  phone: "",
};
const initialCodeValues = {
  code: "",
};

export function Login() {
  const { saveAuth, setCurrentUser } = useAuth();
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const resendCode = useCallback(async () => {
    setCodeSent(false);
  }, []);

  const formik = useFormik({
    initialValues: initialPhoneValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(values);
      try {
        await post("auth/send-phone", {
          phone: values.phone,
        });
        setCodeSent(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const codeForm = useFormik({
    initialValues: initialCodeValues,
    validationSchema: codeSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { data: auth } = await post("auth/send-code", {
          code: values.code,
          phone: formik.values.phone,
        });
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.token);
        await setCurrentUser(user);
        localStorage.setItem("token", auth.token);
        const redirectPath = sessionStorage.getItem("redirect");
        if (redirectPath) {
          sessionStorage.removeItem("redirect");
          navigate(`/${redirectPath}`);
        } else {
          navigate(`/dashboard`);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <>
      <div className="app-header small">
        <div className="tf-container">
          <div className="tf-topbar d-flex justify-content-center align-items-center">
            <img
              src="/images/logo-light.png"
              className="hero-logo"
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div className="mt-7 login-section">
        <div className="tf-container">
          <h1>Login</h1>
          {!codeSent ? (
            <>
              <form
                className="form w-100"
                onSubmit={formik.handleSubmit}
                noValidate
                id="kt_login_signin_form"
              >
                <div className="group-input">
                  <label>Phone</label>
                  <input
                    {...formik.getFieldProps("phone")}
                    className={clsx(
                      "form-control bg-transparent",
                      {
                        "is-invalid":
                          formik.touched.phone && formik.errors.phone,
                      },
                      {
                        "is-valid":
                          formik.touched.phone && !formik.errors.phone,
                      },
                    )}
                    type="phone"
                    name="phone"
                    placeholder="(000) 000-0000"
                  />
                </div>
                <button type="submit" className="tf-btn accent large">
                  Log In
                </button>
              </form>
            </>
          ) : (
            <>
              <form
                className="form w-100"
                onSubmit={codeForm.handleSubmit}
                noValidate
                id="kt_login_signin_form"
              >
                <div className="group-input">
                  <label>Verification Code</label>
                  <input
                    {...codeForm.getFieldProps("code")}
                    className={clsx("form-control bg-transparent")}
                    type="code"
                    name="code"
                    autoComplete="off"
                    placeholder="Enter Code Here"
                  />
                </div>
                <button type="submit" className="tf-btn accent large">
                  Verify
                </button>
              </form>
            </>
          )}

          {codeSent && (
            <p className="mb-9 fw-3 text-center ">
              If you need a new code,
              <a
                onClick={() => resendCode()}
                role="button"
                className="auth-link-rg"
              >
                Resend Code Now
              </a>
            </p>
          )}
        </div>
      </div>
    </>
  );
}
