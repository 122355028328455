import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function get(url: string) {
  const token = localStorage.getItem("kt-auth-react-v");
  let headers;
  if (token) {
    headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token).token}`,
      },
    };
  }
  return axios.get(`${API_URL}/${url}`, headers);
}
