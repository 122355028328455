import { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { get } from "../auth/core/helpers";
import { useAuth } from "../../modules/auth";
import Footer from "../../modules/Footer";

function History(props) {
  const { currentUser } = useAuth();
  console.log(currentUser);
  const [historyData, setHistoryData] = useState<any | null>("");
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    get("api/get-my-history").then(({ data }) => {
      console.log(data);
      setHistoryData(data);
    });
  }, []);

  return (
    <>
      <div className="header is-fixed">
        <div className="tf-container">
          <div className="tf-statusbar d-flex justify-content-center align-items-center">
            <a href="/dashboard" className="back-btn">
              {" "}
              <i className="icon-left"></i>{" "}
            </a>
            <h3>History</h3>
          </div>
        </div>
      </div>
      <div id="app-wrap">
        <div className="app-section st1 mt-1 mb-5 bg_white_color">
          <div className="tf-container">
            <div className="trading-month">
              <div className="group-trading-history mb-5">
                {historyData &&
                  historyData.map((item) => (
                    <Link
                      className="tf-trading-history"
                      to={`/history/${currentUser?.customer_id}/${item.id}`}
                    >
                      <div className="inner-left">
                        <div className="icon-box rgba_primary">
                          <i className="icon icon-electricity-1"></i>
                        </div>
                        <div className="content">
                          <h4>{item?.title}</h4>
                          <p>
                            <Moment format="MM/DD/YYYY, hh:mm A">
                              {item?.start}
                            </Moment>
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Footer page="history" />
      </div>
    </>
  );
}

export default History;
