import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { AuthInit } from "./modules/auth";
import "moment-timezone";
import moment from "moment";

moment.tz.setDefault("America/Chicago");

const App = () => {
  return (
    <Suspense>
      <I18nProvider>
        <AuthInit>
          <Outlet />
        </AuthInit>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
