import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { post } from "../auth/core/_requests";

interface propState {
  task: any;
  notification: any;
}

function Payment(props) {
  const location = useLocation();
  const { task, notification } = location.state as propState;
  const { stripePromise } = props;
  const [clientSecret, setClientSecret] = useState<any | null>("");
  const stripePromiseFilled = loadStripe(stripePromise);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    post("api/create-payment-intent", { notification, task }).then(
      ({ data }) => {
        setClientSecret(data.client_secret);
      },
    );
  }, [task, notification]);

  return (
    <>
      <div className="app-header st1">
        <div className="tf-container">
          <div className="tf-topbar d-flex justify-content-center align-items-center">
            <a href="/dashboard" className="back-btn">
              <i className="icon-left white_color"></i>
            </a>
            <h3 className="white_color">Pay Now</h3>
          </div>
        </div>
      </div>
      <div className="card-secton transfer-section">
        <div className="tf-container">
          <div className="tf-balance-box transfer-amount">
            <div className="top">
              <i className="icon-group-dollar"></i>
              <h1>${task.price}</h1>
              <h4 className="secondary_color fw_4">Charge Amount</h4>
            </div>
            <div className="bottom d-flex justify-content-between align-items-center">
              <h4 className="secondary_color fw_4">{task.title}</h4>
              <h4>${task.price}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="transfer-card">
        <div className="tf-container">
          {clientSecret && stripePromiseFilled && (
            <Elements stripe={stripePromiseFilled} options={{ clientSecret }}>
              <CheckoutForm />
            </Elements>
          )}
        </div>
      </div>
    </>
  );
}

export default Payment;
