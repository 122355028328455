import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import Payment from "../modules/payment/Payment";
import Completion from "../modules/payment/Completion";
import NotesDetails from "../modules/notes/NotesDetails";
import Notes from "../modules/notes/Notes";
import Notifications from "../modules/notes/Notifications";
import { CreateTask } from "../modules/tasks/CreateTask";
import History from "../modules/nav/History";
import HistoryDetail from "../modules/nav/HistoryDetail";
import { useAuth } from "../modules/auth";

const PrivateRoutes = () => {
  const [stripePromise, setStripePromise] = useState<any | null>(null);

  useEffect(() => {
    setStripePromise(process.env.REACT_APP_STRIPE_API_KEY);
  }, []);
  const { currentUser } = useAuth();
  const clientID = currentUser?.client_id;
  return (
    <Routes>
      <Route>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />

        <Route path={`/task/${clientID}/:id`} element={<CreateTask />} />

        <Route
          path="/payment"
          element={<Payment stripePromise={stripePromise} />}
        />

        <Route
          path="/history/:customer_id/:notification_id"
          element={<HistoryDetail />}
        />

        <Route
          path="/completion"
          element={<Completion stripePromise={stripePromise} />}
        />
        <Route path="/history" element={<History />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/notes" element={<Notes />} />

        <Route path="/car/:car_id" element={<NotesDetails />} />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
