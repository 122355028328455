import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { post } from "../auth/core/_requests";
import { useAuth } from "../../modules/auth";
import Footer from "../../modules/Footer";

function Notes(props) {
  const { currentUser } = useAuth();
  const [cars, setCars] = useState<any>([]);
  useEffect(() => {
    async function fetch() {
      const customerCars = await post("api/get-cars", {
        id: currentUser?.customer_id,
      });
      setCars(customerCars.data);
    }
    if (currentUser) {
      fetch();
    }
  }, [currentUser]);

  return (
    <>
      <div className="header is-fixed">
        <div className="tf-container">
          <div className="tf-statusbar d-flex justify-content-center align-items-center">
            <Link to="/dashboard" className="back-btn">
              {" "}
              <i className="icon-left"></i>{" "}
            </Link>
            <h3>Cars</h3>
          </div>
        </div>
      </div>
      <div id="app-wrap">
        <div className="app-section st1 mt-1 mb-5 bg_white_color">
          <div className="tf-container">
            <div className="trading-month">
              <div className="group-trading-history mb-5">
                {cars &&
                  cars.map((item) => (
                    <Link
                      className="tf-trading-history"
                      to={`/car/${item.car_id}`}
                    >
                      <div className="inner-left">
                        <div className="icon-box rgba_primary">
                          <i className="icon icon-electricity-1"></i>
                        </div>
                        <div className="content">
                          <h4>{item?.name}</h4>
                          <NumericFormat
                            displayType="text"
                            value={item && item.miles}
                            allowLeadingZeros
                            thousandSeparator=","
                          />{" "}
                          miles
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Footer page="notes" />
      </div>
    </>
  );
}

export default Notes;
