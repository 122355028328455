/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { post } from "../auth/core/_requests";
import { useAuth } from "../auth/core/Auth";
import ResponsiveDateTimePickers from "./ResponsiveDateTimePickers";

export function CreateTask() {
    const navigate = useNavigate();

    const { currentUser } = useAuth();
    const [task, setTask] = useState<any>({ title: "" });
    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState<any>(false);
    const { id } = useParams();
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await post("api/get-task", id);
            let incomingTask = response.data;
            incomingTask.fields = JSON.parse(response.data.fields);
            setTask(incomingTask);
        }
        fetchMyAPI();
    }, [id]);

    const onSubmit = async () => {
        setLoading(true);
        const filled = task.fields.filter((item) => !item.value);
        if (filled.length === 0) {
            const notification = await post("api/create-notification", {
                customer: currentUser,
                task,
            });
            if (task?.price && task?.price > 0) {
                navigate("/payment", {
                    state: {
                        task,
                        notification: notification.data.newNotification,
                    },
                });
            } else {
                navigate("/dashboard");
            }
        } else {
            setLoading(false);
            setError("Please fill out all fields");
        }
    };

    const onChange = (e, i) => {
        console.log(e);
        console.log(i);
        let newArr = [...task.fields]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        console.log(newArr);
        newArr[i].value = e; // replace e.target.value with whatever you want to change it to

        setTask({
            ...task,
            fields: newArr,
        });
    };

    const onChangeCheckbox = (e, i) => {
        console.log(e);
        console.log(i);

        let newArr = [...task.fields]; // copying the old datas array
        if (newArr[i] && !newArr[i].value) {
            newArr[i].value = {};
        }

        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.

        newArr[i].value[e.target.name] = e.target.checked;

        //newArr[i].value = e.target.value; // replace e.target.value with whatever you want to change it to
        console.log(newArr);
        setTask({
            ...task,
            fields: newArr,
        });
    };

    const changeField = (index) => (e) => {
        console.log("index: " + index);
        console.log("property value: " + e.target.value);
        let newArr = [...task.fields]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        console.log(newArr);
        newArr[index].value = e.target.value; // replace e.target.value with whatever you want to change it to

        setTask({
            ...task,
            fields: newArr,
        });
    };

    return (
        <>
            <div className="header">
                <div className="tf-container">
                    <div className="tf-statusbar d-flex justify-content-center align-items-center">
                        <Link to="/dashboard" className="back-btn">
                            {" "}
                            <i className="icon-left"></i>{" "}
                        </Link>
                        <h3>{task && task.title}</h3>
                    </div>
                </div>
            </div>
            <div className="mt-6">
                <div className="form w-100">
                    <div className="tf-container">
                        <div className="tf-form">
                            {task &&
                                task.fields &&
                                task.fields.map((field, i) => {
                                    let fieldInput;
                                    switch (field.type) {
                                        case "Textarea":
                                            fieldInput = (
                                                <textarea
                                                    name={`value`}
                                                    value={field.value}
                                                    onChange={changeField(i)}
                                                ></textarea>
                                            );
                                            break;
                                        case "Date":
                                            fieldInput = (
                                                <ResponsiveDateTimePickers
                                                    onChange={(e) =>
                                                        onChange(e, i)
                                                    }
                                                    value={field.value}
                                                />
                                            );
                                            break;
                                        case "Dropdown": {
                                            const fieldInputOptions =
                                                field.options
                                                    .split(",")
                                                    .map((item, index) => (
                                                        <fieldset
                                                            key={`item-${index}`}
                                                            className="fieldset-radio"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="tf-radio square"
                                                                name={item}
                                                                checked={
                                                                    field &&
                                                                    field.value &&
                                                                    field.value[
                                                                        item
                                                                    ]
                                                                }
                                                                id={`options-${index}`}
                                                                onChange={(e) =>
                                                                    onChangeCheckbox(
                                                                        e,
                                                                        i,
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                htmlFor={`options-${index}`}
                                                            >
                                                                {item}
                                                            </label>
                                                        </fieldset>
                                                    ));
                                            fieldInput = (
                                                <div>{fieldInputOptions}</div>
                                            );

                                            break;
                                        }
                                        default:
                                            fieldInput = (
                                                <input
                                                    type={`${field.type}`}
                                                    name={`value`}
                                                    value={field.value}
                                                    onChange={changeField(i)}
                                                />
                                            );
                                    }
                                    return (
                                        <div
                                            key={`field-${i}`}
                                            className={`${
                                                field.type === "Dropdown"
                                                    ? "dropdown-options box-components mt-4 mb-7"
                                                    : "group-input"
                                            }`}
                                        >
                                            <label>{field.label}</label>
                                            {fieldInput}
                                        </div>
                                    );
                                })}
                            {error && <p className="error">{error}</p>}
                            <div className="group-btn-change-name">
                                <Link
                                    to="/dashboard"
                                    className="tf-btn light large"
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    onClick={onSubmit}
                                    className="tf-btn accent large"
                                >
                                    {loading
                                        ? "Submitting"
                                        : `${
                                              task?.price && task?.price > 0
                                                  ? "Pay Now"
                                                  : "Submit"
                                          }`}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
