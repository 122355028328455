/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { get } from "../auth/core/helpers";
import Footer from "../../modules/Footer";
import { post } from "../auth/core/_requests";
import { useAuth } from "../auth/core/Auth";
import { NotesCard } from "./cards/NotesCard";
import ResponsiveDateTimePickers from "../tasks/ResponsiveDateTimePickers";

function HistoryDetail(props) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  let [searchParams] = useSearchParams();
  const [notification, setNotication] = useState<any>({ title: "" });
  const [car, setCar] = useState<any>(null);
  const [notes, setNotes] = useState<any>(null);
  const [editing, setEditing] = useState<any>(false);
  const { notification_id } = useParams();

  useEffect(() => {
    async function fetch() {
      let project = await get(`api/get-my-history-item/${notification_id}`);
      setNotication(project.data);

      const carData = await post("api/get-car", {
        car_id: project.data?.car_id,
      });
      setCar(carData.data);

      const carNotes = await post("api/get-project-notes", {
        notification_id: project.data?.id,
      });
      setNotes(carNotes.data);
      setTimeout(() => {
        const note_id = searchParams.get("note_id");
        if (note_id) {
          const section = document.querySelector(`#comments-${note_id}`);
          section?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1000);
    }

    fetch();
  }, [notification_id, searchParams]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    get(`api/get-my-history-item/${notification_id}`).then(({ data }) => {
      console.log(data);
      data.fields = JSON.parse(data.fields);
      data.existing = true;
      setNotication(data);
    });
  }, [notification_id]);

  const onSubmit = async () => {
    await post("api/customer-update-notification", {
      customer: currentUser,
      notification,
    });
    navigate("/dashboard");
  };

  const changeField = (index) => (e) => {
    console.log("index: " + index);
    console.log("property value: " + e.target.value);
    let newArr = [...notification.fields]; // copying the old datas array
    // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
    console.log(newArr);
    newArr[index].value = e.target.value; // replace e.target.value with whatever you want to change it to

    setNotication({
      ...notification,
      fields: newArr,
    });
  };

  const onChange = (e, i) => {
    console.log(e);
    console.log(i);
    let newArr = [...notification.fields]; // copying the old datas array
    // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
    console.log(newArr);
    newArr[i].value = e; // replace e.target.value with whatever you want to change it to

    setNotication({
      ...notification,
      fields: newArr,
    });
  };

  const edit = () => {
    setEditing(!editing);
  };

  return (
    <>
      <div className="header is-fixed">
        <div className="tf-container">
          <div className="tf-statusbar d-flex justify-content-center align-items-center">
            <Link to="/history" className="back-btn">
              {" "}
              <i className="icon-left"></i>{" "}
            </Link>
            <h3>{notification && notification.title}</h3>
            <button onClick={edit} className="forward-btn">
              {" "}
              {editing ? "Cancel" : <i className="icon-edit"></i>}
            </button>
          </div>
        </div>
      </div>
      <div id="app-wrap">
        {editing ? (
          <div className="mt-3">
            <div className="form w-100">
              <div className="tf-container">
                <div className="tf-form">
                  {notification &&
                    notification.fields &&
                    notification.fields.map((field, i) => {
                      let fieldInput;
                      switch (field.type) {
                        case "Textarea":
                          fieldInput = (
                            <textarea
                              name={`value`}
                              value={field.value}
                              onChange={changeField(i)}
                            ></textarea>
                          );
                          break;
                        case "Date":
                          fieldInput = (
                            <ResponsiveDateTimePickers
                              onChange={(e) => onChange(e, i)}
                              value={field.value}
                            />
                          );
                          break;
                        default:
                          fieldInput = (
                            <input
                              type={`${field.type}`}
                              name={`value`}
                              value={field.value}
                              onChange={changeField(i)}
                            />
                          );
                      }
                      return (
                        <div className="group-input">
                          <label>{field.label}</label>
                          {fieldInput}
                        </div>
                      );
                    })}
                  <div className="group-btn-change-name">
                    <Link to="/dashboard" className="tf-btn light large">
                      Cancel
                    </Link>
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="tf-btn accent large"
                    >
                      {notification?.price && notification?.price > 0
                        ? "Pay Now"
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {notes &&
              notes.length > 0 &&
              notes.map((note) => (
                <NotesCard note={note} car={car} currentUser={currentUser} />
              ))}
          </div>
        )}
      </div>
      <Footer page="notes" />
    </>
  );
}

export default HistoryDetail;
