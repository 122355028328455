/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { useLocation, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { post } from "../../auth/core/_requests";

type Props = {
  note: any;
  car: any;
  currentUser: any;
};

const NotesCard: React.FC<Props> = ({ note, car, currentUser }) => {
  const [reply, setReply] = useState<any>("");
  const [replies, setReplies] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const history = useLocation();
  useEffect(() => {
    async function fetch() {
      const repliesData = await post("api/get-replies", { note });
      setReplies(repliesData.data);
    }
    fetch();
  }, [history, note]);

  const sendReply = async () => {
    await post("api/save-reply", { reply, note });
    setReply("");
    setSearchParams({
      refresh: Math.floor(Date.now() / 1000).toString(),
    });
    console.log(searchParams);
  };
  const writeMessage = (e) => {
    setReply(e.target.value);
  };
  return (
    <div id={`note-${note.id}`} className={`mb-5`} key={note.id}>
      <div className={`card `}>
        {/* begin::Body */}
        <div className="card-body pb-0">
          <div className="d-flex align-items-center mb-5">
            {/* begin::User */}
            <div className="d-flex align-items-center flex-grow-1">
              {/* begin::Avatar */}

              {/* end::Avatar */}

              {/* begin::Info */}
              <div className="d-flex flex-column">
                <h2 className="text-gray-900 text-hover-primary fs-6 fw-bold">
                  {car.name}
                </h2>
                <span className="text-gray-400 fw-semibold">
                  <Moment format="LL">{note.created_at}</Moment>
                </span>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* end::Menu */}
          </div>
          {/* end::Header */}
          {/* begin::Post */}
          <div className="row">
            <div
              className={`${
                note.images && JSON.parse(note.images).length > 0
                  ? "col-md-12"
                  : "col-md-12 mb-on-note"
              }`}
            >
              <div className="text-gray-800 mb-2 mb-on-note-child">
                <div dangerouslySetInnerHTML={{ __html: note.note }} />
              </div>
            </div>
            {note.images && JSON.parse(note.images).length > 0 && (
              <div className="mb-5 col-md-12">
                {/* begin::Image */}
                {note.images &&
                  JSON.parse(note.images).length > 0 &&
                  JSON.parse(note.images).map((image, i) => (
                    <img
                      className="mb-5"
                      src={`${process.env.REACT_APP_API_URL}/storage/${image}`}
                      alt={`note-${i}`}
                    />
                  ))}
                {/* end::Image */}

                {/* begin::Text */}

                {/* end::Text */}
              </div>
            )}
          </div>
        </div>
        <div id={`comments-${note.id}`} className="replies-box">
          {replies &&
            replies.length > 0 &&
            replies.map((item) => (
              <div className="reply-item">
                <div className="image-box flex">
                  <h4>{item.sender.name}</h4>
                  <Moment fromNow>{item.created_at}</Moment>
                </div>{" "}
                <p>{item.message}</p>
              </div>
            ))}
        </div>
        <div className="position-relative mb-6 reply-box">
          <textarea
            className="form-control border-0  resize-none min-h-50px"
            placeholder="Reply..."
            onChange={writeMessage}
            value={reply}
          ></textarea>
          {reply && (
            <button className="btn btn-primary" onClick={sendReply}>
              Send
            </button>
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  );
};

export { NotesCard };
