import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

export default function ResponsiveDateTimePickers({ value, onChange }) {
  const passValue = (e) => {
    onChange(e.$d);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["MobileDateTimePicker"]}>
        <DemoItem>
          <MobileDateTimePicker
            defaultValue={dayjs(new Date())}
            minTime={dayjs().set("hour", 8)}
            value={dayjs(value)}
            maxTime={dayjs().set("hour", 17)}
            onChange={passValue}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
