import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { NotesCard } from "../nav/cards/NotesCard";
import { post } from "../auth/core/_requests";
import { useAuth } from "../../modules/auth";
import Footer from "../../modules/Footer";

function NotesDetails(props) {
  const { currentUser } = useAuth();
  const { car_id } = useParams();
  let [searchParams] = useSearchParams();
  const [notes, setNotes] = useState<any>([]);
  const [car, setCar] = useState<any>(null);

  useEffect(() => {
    async function fetch() {
      const carData = await post("api/get-car", { car_id });
      setCar(carData.data);

      const carNotes = await post("api/get-notes", { car_id });
      setNotes(carNotes.data);

      setTimeout(() => {
        const note_id = searchParams.get("note_id");
        if (note_id) {
          const section = document.querySelector(`#comments-${note_id}`);
          section?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1000);
    }
    fetch();
  }, [car_id, searchParams]);

  return (
    <>
      <div className="header is-fixed">
        <div className="tf-container">
          <div className="tf-statusbar d-flex justify-content-center align-items-center">
            <Link to="/notes" className="back-btn">
              {" "}
              <i className="icon-left"></i>{" "}
            </Link>
            <h3>{car && car.name}</h3>
          </div>
        </div>
      </div>
      <div id="app-wrap">
        <div className="app-section st1 mt-1 mb-5 bg_white_color">
          <div className="tf-container">
            <div className="trading-month">
              <div className="group-trading-history mb-5">
                {notes &&
                  notes.map((note) => (
                    <NotesCard
                      note={note}
                      car={car}
                      currentUser={currentUser}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Footer page="notes" />
      </div>
    </>
  );
}

export default NotesDetails;
