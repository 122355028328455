import { Link } from "react-router-dom";

export default function Footer(props) {
  const { page } = props;
  console.log(page);
  return (
    <div className="bottom-navigation-bar">
      <div className="tf-container">
        <ul className="tf-navigation-bar">
          <li className={page === "dashboard" ? "active" : ""}>
            <Link
              className="fw_6 d-flex justify-content-center align-items-center flex-column"
              to="/dashboard"
            >
              <i className="footer-fonticon fonticon-home"></i> Home
            </Link>{" "}
          </li>
          <li className={page === "history" ? "active" : ""}>
            <Link
              className="fw_4 d-flex justify-content-center align-items-center flex-column"
              to="/history"
            >
              <i className="footer-fonticon fonticon-layers"></i> Projects
            </Link>{" "}
          </li>
          <li className={page === "notes" ? "active" : ""}>
            <Link
              className="fw_4 d-flex justify-content-center align-items-center flex-column"
              to="/notes"
            >
              <i className="footer-fonticon fonticon-image"></i> Notes
            </Link>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
}
